import { Outlet, useLocation, useRoutes } from "react-router";
import { Services } from "../pages";

export function Router() {

    return useRoutes([
        {
            path: '/',
            element: <Services />
        },
        {
            path: '*',
            element: <></>
        }
    ]);

}