import { createContext, useContext, useEffect, useReducer } from "react";
import { useCookies } from 'react-cookie';

const initialState = {
    authenciated: false,
    initialized: false,
    account: null,
    account_type: -1,
    setting: null
}

const handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            ...action.payload,
            initialized: true
        }
    },
    DATA: (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState
});

function getExpirationTime() {
    // 4 MONTHS
    var mo4 = new Date();
    var today = new Date;
    mo4.setDate(today.getDate() + 30 * 4);
    return mo4;
}

function AuthProvider({ children }) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const [cookies, setCookie, removeCookie] = useCookies([]);

    const getAccessToken = () => {
        return cookies['token'];
    }

    useEffect(() => {

        console.log("initializing authctx");

        return () => { };

    }, []);


    return (
        <AuthContext.Provider
            value={{
                ...state
            }}>
            {children}
        </AuthContext.Provider>
    )

}

const useAuth = () => useContext(AuthContext);

export {
    AuthContext,
    AuthProvider,
    useAuth
};
